import React, { createContext, useContext, useState } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";

const ConfirmDialogContext = createContext();

export const ConfirmDialogProvider = ({ children }) => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [header, setHeader] = useState("");
  const [icon, setIcon] = useState("");
  const [accept, setAccept] = useState(null);
  const [reject, setReject] = useState(null);

  const showConfirmDialog = ({ message, header, icon, onAccept, onReject }) => {
    setMessage(message);
    setHeader(header ?? "Confirm");
    setIcon(icon ?? "pi pi-exclamation-triangle text-danger fs-1");
    setVisible(true);
    setAccept(() => onAccept ?? (() => setVisible(false)));
    setReject(() => onReject ?? (() => setVisible(false)));
  };

  return (
    <ConfirmDialogContext.Provider value={showConfirmDialog}>
      <ConfirmDialog
        group="declarative"
        visible={visible}
        onHide={() => setVisible(false)}
        message={message}
        header={header}
        icon={icon}
        accept={accept}
        reject={reject}
        style={{ width: "25vw" }}
        breakpoints={{ "960px": "50vw", "640px": "75vw" }}
        acceptClassName="btn btn-info ms-2"
        rejectClassName="btn border"
      />
      {children}
    </ConfirmDialogContext.Provider>
  );
};

export const useConfirmDialogProvider = () => useContext(ConfirmDialogContext);