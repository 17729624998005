import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button, Form } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import { useToastProvider } from "../../providers/useToastProvider";
import { InputText } from "primereact/inputtext";

function NewGroupProfile(props) {
  const { show, toggle, getProfiles, isUpdate, selectedGroupProfile } = props;
  const showToast = useToastProvider();

  const [groupProfile, setGroupProfile] = useState("");

  const groupProfileRef = useRef(null);
  const saveBtnRef = useRef(null);

  const saveGroupProfile = async () => {
    try {
      saveBtnRef.current.disabled = true;
      saveBtnRef.current.innerText = "Please wait...";

      const url = isUpdate
        ? "settings/groupProfile/update/" + selectedGroupProfile.id
        : "settings/groupProfile/create";
      const response = await protectedAPI.post(url, {
        name: groupProfile,
      });
      if (response.status === 201 || response.status === 200) {
        getProfiles();
        showToast({
          type: "success",
          detail: response.data.message,
        });
        clearFields();
        toggle();
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      saveBtnRef.current.disabled = false;
      saveBtnRef.current.innerText = "Save";
    }
  };

  const clearFields = () => {
    setGroupProfile("");
  };

  useEffect(() => {
    if (isUpdate && selectedGroupProfile) {
      setGroupProfile(selectedGroupProfile.name);
    } else {
      clearFields();
    }
  }, [isUpdate, selectedGroupProfile]);

  return (
    <>
      <Dialog
        header={isUpdate ? "Update Group Profile Details" : "New Group Profile"}
        visible={show}
        style={{ width: "25vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => {
          toggle();
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="groupProfile">Profile Name</Form.Label>
              <InputText
                id="groupProfile"
                ref={groupProfileRef}
                value={groupProfile}
                onChange={(e) => setGroupProfile(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end">
            <Button
              variant="info"
              className="p-2 px-4"
              ref={saveBtnRef}
              onClick={saveGroupProfile}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default NewGroupProfile;
