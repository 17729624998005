import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button, Form } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import { useToastProvider } from "../../providers/useToastProvider";
import { InputText } from "primereact/inputtext";
import { useMenuProvider } from "../../providers/useMenuProvider";

function NewMenuCategory(props) {
  const { show, toggle, getCategories, isUpdate, selectedMenuCategory } = props;
  const showToast = useToastProvider();
  const { getMenuItems } = useMenuProvider();

  const [menuCategory, setMenuCategory] = useState("");
  const [icon, setIcon] = useState("");
  const [order, setOrder] = useState("");

  const menuCategoryRef = useRef(null);
  const iconRef = useRef(null);
  const orderRef = useRef(null);
  const saveBtnRef = useRef(null);

  const saveMenuCategory = async () => {
    try {
      saveBtnRef.current.disabled = true;
      saveBtnRef.current.innerText = "Please wait...";

      const url = isUpdate
        ? "settings/menuCategory/update/" + selectedMenuCategory.id
        : "settings/menuCategory/create";
      const response = await protectedAPI.post(url, {
        name: menuCategory,
        icon: icon || null,
        order: order || 0,
      });
      if (response.status === 201 || response.status === 200) {
        getMenuItems();
        getCategories();
        showToast({
          type: "success",
          detail: response.data.message,
        });
        clearFields();
        toggle();
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      saveBtnRef.current.disabled = false;
      saveBtnRef.current.innerText = "Save";
    }
  };

  const clearFields = () => {
    setMenuCategory("");
    setIcon("");
    setOrder("");
  };

  useEffect(() => {
    if (isUpdate && selectedMenuCategory) {
      setMenuCategory(selectedMenuCategory.name);
      setIcon(selectedMenuCategory.icon || "");
      setOrder(selectedMenuCategory.order || "");
    } else {
      clearFields();
    }
  }, [isUpdate, selectedMenuCategory]);

  return (
    <>
      <Dialog
        header={isUpdate ? "Update Menu Category Details" : "New Menu Category"}
        visible={show}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => {
          toggle();
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="menuCategory">Category Name</Form.Label>
              <InputText
                id="menuCategory"
                ref={menuCategoryRef}
                value={menuCategory}
                onChange={(e) => setMenuCategory(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="icon">Icon</Form.Label>
              <InputText
                id="icon"
                ref={iconRef}
                value={icon}
                onChange={(e) => setIcon(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-4">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="order">Order</Form.Label>
              <InputText
                id="order"
                ref={orderRef}
                value={order}
                onChange={(e) => setOrder(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end">
            <Button
              variant="info"
              className="p-2 px-4"
              ref={saveBtnRef}
              onClick={saveMenuCategory}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default NewMenuCategory;
