import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button, Form } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import { useToastProvider } from "../../providers/useToastProvider";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";

function NewEmployee(props) {
  const { show, toggle, getEmployees, isUpdate, selectedEmployee } = props;
  const showToast = useToastProvider();

  const [empNo, setEmpNo] = useState("");
  const [firstname, setFirstname] = useState("");
  const [fullname, setFullname] = useState("");
  const [division, setDivision] = useState("");
  const [nic, setNic] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState(null);
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [photo, setPhoto] = useState(null);

  const empNoRef = useRef(null);
  const firstnameRef = useRef(null);
  const fullnameRef = useRef(null);
  const divisionRef = useRef(null);
  const nicRef = useRef(null);
  const addressRef = useRef(null);
  const dobRef = useRef(null);
  const mobileRef = useRef(null);
  const emailRef = useRef(null);
  const photoRef = useRef(null);
  const saveBtnRef = useRef(null);

  const saveEmployee = async () => {
    try {
      saveBtnRef.current.disabled = true;
      saveBtnRef.current.innerText = "Please wait...";

      const formData = new FormData();
      formData.append("empNo", empNo);
      formData.append("nickname", firstname);
      formData.append("fullname", fullname);
      formData.append("division", division);
      formData.append("nic", nic);
      formData.append("address", address);
      formData.append("birthday", dob ? formatDate(dob) : "");
      formData.append("mobile", mobile);
      formData.append("email", email);
      if (photo) {
        formData.append("image", photo);
      }

      const url = isUpdate
        ? "employee/update/" + selectedEmployee.empNo
        : "employee/create";
      const response = await protectedAPI.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 201 || response.status === 200) {
        getEmployees();
        showToast({
          type: "success",
          detail: response.data.message,
        });
        clearFields();
        toggle();
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      saveBtnRef.current.disabled = false;
      saveBtnRef.current.innerText = "Save Employee";
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const clearFields = () => {
    setEmpNo("");
    setFirstname("");
    setFullname("");
    setDivision("");
    setNic("");
    setAddress("");
    setDob("");
    setMobile("");
    setEmail("");
    setPhoto(null);
  };

  useEffect(() => {
    if (isUpdate && selectedEmployee) {
      setEmpNo(selectedEmployee.empNo);
      setFirstname(selectedEmployee.nickname);
      setFullname(selectedEmployee.fullname || "");
      setDivision(selectedEmployee.division || "");
      setNic(selectedEmployee.nic || "");
      setAddress(selectedEmployee.address || "");
      setDob(
        selectedEmployee.birthday ? new Date(selectedEmployee.birthday) : ""
      );
      setMobile(selectedEmployee.mobile || "");
      setEmail(selectedEmployee.email || "");
    } else {
      clearFields();
    }
  }, [isUpdate, selectedEmployee]);

  return (
    <>
      <Dialog
        header={isUpdate ? "Update Employee Details" : "New Employee"}
        visible={show}
        style={{ width: "75vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => {
          toggle();
        }}
      >
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="empNo">Employee No.</Form.Label>
              <InputText
                id="empNo"
                ref={empNoRef}
                value={empNo}
                onChange={(e) => setEmpNo(e.target.value)}
                disabled={isUpdate}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="firstname">Nick/First Name</Form.Label>
              <InputText
                id="firstname"
                ref={firstnameRef}
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="fullname">Full Name</Form.Label>
              <InputText
                id="fullname"
                ref={fullnameRef}
                value={fullname}
                onChange={(e) => setFullname(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="division">Division</Form.Label>
              <InputText
                id="division"
                ref={divisionRef}
                value={division}
                onChange={(e) => setDivision(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="nic">NIC</Form.Label>
              <InputText
                id="nic"
                ref={nicRef}
                value={nic}
                onChange={(e) => setNic(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="address">Address</Form.Label>
              <InputText
                id="address"
                ref={addressRef}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="dob">Date of Birth</Form.Label>
              <Calendar value={dob} onChange={(e) => setDob(e.value)} />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="mobile">Mobile</Form.Label>
              <InputText
                id="mobile"
                ref={mobileRef}
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-6">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="email">Email</Form.Label>
              <InputText
                id="email"
                type="email"
                ref={emailRef}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="photo">Photo</Form.Label>
              <InputText
                id="photo"
                type="file"
                ref={photoRef}
                onChange={(e) => setPhoto(e.target.files[0])}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end">
            <Button
              variant="info"
              className="p-2 px-4"
              ref={saveBtnRef}
              onClick={saveEmployee}
            >
              Save Employee
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default NewEmployee;
