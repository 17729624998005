import React, { useEffect, useState } from "react";
import MenuCategorySubItem from "./MenuCategorySubItem";
import Collapse from "react-bootstrap/Collapse";

function MenuCategoryItem(props) {
  const { item, isOpen, onToggle, keyx } = props;
  return (
    <>
      <button
        className={`btn menu-category-btn ${
          isOpen ? "menu-category-btn-hovered" : ""
        } d-flex justify-content-between`}
        style={{ fontWeight: "400" }}
        onClick={onToggle}
        aria-controls={keyx}
        aria-expanded={isOpen}
      >
        <span>
          <i className={item.icon + " me-2"} style={{ fontSize: "16px" }}></i>{" "}
          {item.label}
        </span>
        <i
          className={isOpen ? "bi bi-chevron-down" : "bi bi-chevron-right"}
        ></i>
      </button>
      <Collapse in={isOpen} className="menu-collapse">
        <div id={keyx}>
          <MenuCategorySubItem items={item.items} />
        </div>
      </Collapse>
    </>
  );
}

export default MenuCategoryItem;
