import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Forbidden() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("privilegedViews");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    localStorage.setItem(
      "logout_msg",
      "You have been logged out due to a SECURITY REASON. Please log in again."
    );
  }, []);

  return (
    <>
      <div
        className="d-flex main-content"
        style={{
          background: "linear-gradient(140deg, #090c11 50%, #161c25 100%)",
        }}
      >
        <div className="d-flex flex-column justify-content-center align-items-center w-100">
          <i
            className="pi pi-exclamation-triangle text-danger mb-5"
            style={{ fontSize: "72px" }}
          ></i>
          <h1 className="fw-bold">Unauthorized Access</h1>
          <span className="mb-5 text-wrap">
            This is a protected resource and only authorized users can access.
            If you think this is a mistake, please contact the administrator.
          </span>
          <button className="btn btn-info" onClick={() => navigate("/")}>
            Go Back
          </button>
        </div>
      </div>
    </>
  );
}

export default Forbidden;
