import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

function Register() {
  const nameRef = useRef("");
  const registrationNoRef = useRef("");
  const addressRef = useRef("");
  const emailRef = useRef("");
  const phoneRef = useRef("");
  const usernameRef = useRef("");
  const passwordRef = useRef("");
  const showPasswordRef = useRef(0);

  const navigate = useNavigate();

  const showPassword = () => {
    if (passwordRef.current.type === "password") {
      passwordRef.current.type = "text";
    } else {
      passwordRef.current.type = "password";
    }
  };

  return (
    <>
      <div className="container pt-5 pb-5" style={{overflowY: "auto"}}>
        <div className="row justify-content-center">
          <div className="col-md-8 col-lg-4 col-xl-4">
            <div className="card bg-body-tertiary mt-4">
              <div className="card-body p-4">
                <div className="text-center mt-2">
                  <h5 className="text-primary">Welcome Back !</h5>
                  <p className="text-muted">
                    Register your company with Nexl for Smart Project
                    Management.
                  </p>
                </div>
                <div className="p-2 mt-4">
                  <form action="javascript:void(0)">
                    <div className="mb-3">
                      <label htmlFor="name" className="form-label">
                        Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        ref={nameRef}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="registration" className="form-label">
                        Registration Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        ref={registrationNoRef}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="address" className="form-label">
                        Address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        ref={addressRef}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="phone" className="form-label">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        ref={phoneRef}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        ref={emailRef}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="username" className="form-label">
                        Username
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        ref={usernameRef}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="password-input">
                        Password
                      </label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <input
                          type="password"
                          className="form-control pe-5 password-input"
                          ref={passwordRef}
                        />
                        <button
                          className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          type="button"
                          ref={showPasswordRef}
                          onClick={showPassword}
                        >
                          <i className="ri-eye-fill align-middle"></i>
                        </button>
                      </div>
                    </div>
                    <div className="mt-4">
                      <button className="btn btn-success w-100" type="submit">
                        Register
                      </button>
                    </div>
                    <div className="mt-4 text-center">
                      <p className="mb-0">
                        Already have an account ?
                        <Link
                          to="/"
                          className="fw-semibold text-primary text-decoration-underline"
                        >
                          <br />
                          Sign In
                        </Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="mt-4 text-center">
              <p className="mb-0">
                Software By{" "}
                <a
                  href="https://wa.me/+94775547473"
                  className="fw-semibold text-body-secondary text-decoration-underline"
                >
                  Hasintha Nayanajith.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Register;
