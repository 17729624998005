import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button, Form } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import { useToastProvider } from "../../providers/useToastProvider";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";

function NewModule(props) {
  const { show, toggle, getModules, isUpdate, selectedModule } = props;
  const showToast = useToastProvider();

  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");

  const selectedProjectRef = useRef(null);
  const titleRef = useRef(null);
  const codeRef = useRef(null);
  const descriptionRef = useRef(null);
  const saveBtnRef = useRef(null);

  const saveModule = async () => {
    try {
      if (!title || !code) {
        showToast({
          type: "warn",
          detail: "Title and code are required.",
        });
        return;
      }

      saveBtnRef.current.disabled = true;
      saveBtnRef.current.innerText = "Please wait...";

      const url = isUpdate
        ? "module/update/" + selectedModule.id
        : "module/create";
      const response = await protectedAPI.post(url, {
        projectId: selectedProject.id,
        title: title,
        code: code,
        description: description,
      });
      if (response.status === 201 || response.status === 200) {
        getModules(selectedProject);
        showToast({
          type: "success",
          detail: response.data.message,
        });
        clearFields();
        toggle();
      }
    } catch (error) {
      console.error(error);
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      saveBtnRef.current.disabled = false;
      saveBtnRef.current.innerText = "Save";
    }
  };

  const clearFields = () => {
    setSelectedProject(null);
    setTitle("");
    setCode("");
    setDescription("");
  };

  const getProjects = async () => {
    try {
      const response = await protectedAPI.get("project/all");
      if (response.status === 200) {
        setProjects(response.data);
        if (isUpdate && selectedModule) {
          setSelectedProject(
            response.data.find(
              (project) => project.id === selectedModule.projectId
            )
          );
        }
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  useEffect(() => {
    if (isUpdate && selectedModule) {
      setTitle(selectedModule.title);
      setCode(selectedModule.code);
      setDescription(selectedModule.description);
    } else {
      clearFields();
    }

    getProjects();
  }, [isUpdate, selectedModule]);

  return (
    <>
      <Dialog
        header={isUpdate ? "Update Module Details" : "New Module"}
        visible={show}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => {
          toggle();
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="projectId">Project</Form.Label>
              <Dropdown
                ref={selectedProjectRef}
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.value)}
                options={projects}
                filter
                optionLabel="title"
                placeholder="Select a Project"
                className="w-full md:w-14rem"
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="code">Code</Form.Label>
              <InputText
                id="code"
                ref={codeRef}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-9">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <InputText
                id="title"
                ref={titleRef}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="description">Description</Form.Label>
              <InputTextarea
                id="description"
                ref={descriptionRef}
                value={description}
                rows={5}
                cols={30}
                autoResize
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end">
            <Button
              variant="info"
              className="p-2 px-4"
              ref={saveBtnRef}
              onClick={saveModule}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default NewModule;
