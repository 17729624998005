import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useToastProvider } from "../../providers/useToastProvider";
import publicAPI from "../../config/publicAPI";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const usernameRef = useRef("");
  const passwordRef = useRef("");
  const submitBtnRef = useRef("");

  const showToast = useToastProvider();
  const navigate = useNavigate();

  useEffect(() => {
    const logoutMsg = localStorage.getItem("logout_msg");
    if (logoutMsg) {
      showToast({
        type: "warn",
        detail: logoutMsg,
      });
      localStorage.removeItem("logout_msg");
    }
  });

  const login = async () => {
    try {
      submitBtnRef.current.disabled = true;
      submitBtnRef.current.innerText = "Authenticating...";
      const response = await publicAPI.post("auth/login", {
        username: username,
        password: password,
      });
      if (response.status === 200) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        showToast({
          type: "success",
          detail: "You have successfully logged in.",
        });
        navigate("/dashboard", { replace: true });
      }
    } catch (error) {
      usernameRef.current.focus();  
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      submitBtnRef.current.disabled = false;
      submitBtnRef.current.innerText = "Sign In";
    }
  };

  const showPassword = () => {
    if (passwordRef.current.type === "password") {
      passwordRef.current.type = "text";
    } else {
      passwordRef.current.type = "password";
    }
  };

  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          background: "linear-gradient(140deg, #3d4a6150 0%, #0dcaf230 100%)",
        }}
      >
        <div className="d-flex justify-content-center py-5">
          <Card className="dark-gradient-bg border p-3" style={{ width: "25rem" }}>
            <div className="d-flex flex-column justify-content-around text-center">
              <h3 className="fw-bold text-info">Sign In</h3>
              <small className="mt-1">
                We want to confirm that its really you.
              </small>
              <hr />
              <span className="text-start">Username</span>
              <InputText
                ref={usernameRef}
                className="mt-1 fw-bold"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    passwordRef.current.focus();
                  }
                }}
              />
              <span className="text-start mt-3">Password</span>
              <InputText
                ref={passwordRef}
                className="mt-1 mb-2 fw-bold"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    login();
                  }
                }}
              />
              <hr />
              <button
                className="btn btn-info mt-3"
                ref={submitBtnRef}
                onClick={login}
              >
                Sign Me In
              </button>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Login;
