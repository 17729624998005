import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/Menubar/MenuBar";
import { useToastProvider } from "../../providers/useToastProvider";
import NewEmployee from "../../components/Employees/NewEmployee";
import { Button } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import { UPLOADS_URL } from "../../config/api";
import CustomDataTable from "../../components/Tables/CustomDataTable";
import { ProgressSpinner } from "primereact/progressspinner";
import { Card } from "primereact/card";
import { useConfirmDialogProvider } from "../../providers/useConfirmDialogProvider";

function AllEmployees() {
  // const navigate = useNavigate();
  const showToast = useToastProvider();
  const showConfirmDialog = useConfirmDialogProvider();

  const [isLoading, setIsLoading] = useState(false);
  const [showNewEmployeeModal, setShowNewEmployeeModal] = useState(false);
  const [employees, setEmployees] = useState([]);

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const toggleNewEmployeeModal = () => {
    setShowNewEmployeeModal(!showNewEmployeeModal);
  };

  const getEmployees = async () => {
    try {
      setIsLoading(true);
      const response = await protectedAPI.get("employee/all");
      if (response.status === 200) {
        setEmployees(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteEmployee = async (empNo) => {
    try {
      const response = await protectedAPI.delete("employee/delete/" + empNo);
      if (response.status === 200) {
        getEmployees();
        showToast({
          type: "success",
          detail: response.data.message,
        });
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const deleteEmpConfirm = (empNo) => {
    showConfirmDialog({
      message: "Are you sure you want to delete this employee?",
      onAccept: () => deleteEmployee(empNo),
    });
  };

  const openEditModal = (employee) => {
    setSelectedEmployee(employee);
    setIsUpdate(true);
    toggleNewEmployeeModal();
  };

  const rowNoTemplate = (data, options) => {
    return options.rowIndex + 1;
  };

  const nameTemplate = (employee) => {
    return (
      <>
        <div className="d-flex flex-column">
          <span className="fw-bold">
            {employee.fullname || employee.nickname}
          </span>
          <span>EMP No. {employee.empNo}</span>
        </div>
      </>
    );
  };

  const contactTemplate = (employee) => {
    return (
      <>
        <div className="d-flex flex-column">
          <span>{employee.email}</span>
          <span>{employee.mobile}</span>
        </div>
      </>
    );
  };

  const imageTemplate = (employee) => {
    return (
      <>
        <img
          src={UPLOADS_URL + "employees/" + (employee.image || "no-user.png")}
          alt={employee.nickname}
          className="img-fluid rounded-circle"
          style={{ width: "45px", height: "45px" }}
        />
      </>
    );
  };

  const actionTemplate = (employee) => {
    return (
      <>
        <div className="btn-group" role="group">
          <button
            type="button"
            className="btn btn-sm bg-transparent border"
            onClick={() => openEditModal(employee)}
          >
            <i className="pi pi-pencil"></i>
          </button>
          <button
            type="button"
            className="btn btn-sm bg-transparent border text-danger"
            onClick={() => deleteEmpConfirm(employee.empNo)}
          >
            <i className="pi pi-trash"></i>
          </button>
        </div>
      </>
    );
  };

  const employeeTableData = {
    fetchData: getEmployees,
    records: employees,
    exportColumns: [
      { header: "ID", field: "id" },
      { header: "Name", field: "nickname" },
      { header: "Full Name", field: "fullname" },
      { header: "NIC", field: "nic" },
      { header: "EMP No.", field: "empNo" },
      { header: "Acc No.", field: "accNo" },
      { header: "Address", field: "address" },
      { header: "Birthday", field: "birthday" },
      { header: "Division", field: "division" },
      { header: "Mobile", field: "mobile" },
      { header: "Email", field: "email" },
    ],
    tableColumns: [
      { header: "#", body: rowNoTemplate },
      { header: "Photo", body: imageTemplate },
      { header: "ID", field: "id", style: { display: "none" } },
      { header: "First Name", field: "nickname", style: { display: "none" } },
      { header: "Full Name", field: "fullname", style: { display: "none" } },
      { header: "NIC", field: "nic", style: { display: "none" } },
      { header: "EMP No.", field: "empNo", style: { display: "none" } },
      { header: "Name", body: nameTemplate },
      { header: "Acc No.", field: "accNo", sortable: true },
      { header: "Address", field: "address", sortable: true },
      { header: "Contact", body: contactTemplate },
      { header: "", body: actionTemplate },
    ],
    globalFilterFields: [
      "nickname",
      "nic",
      "empNo",
      "accNo",
      "fullname",
      "address",
      "mobile",
      "email",
    ],
    dataKey: "id",
  };

  useEffect(() => {
    getEmployees();
  }, []);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="Employees Directory" />
        <div className="d-flex flex-column p-4">
          <div className="d-flex">
            <Button
              variant="info"
              onClick={() => {
                setSelectedEmployee(null);
                setIsUpdate(false);
                toggleNewEmployeeModal();
              }}
            >
              <i className="pi pi-plus me-1"></i> New Employee
            </Button>
          </div>
          <div className="mt-3">
            {!isLoading ? (
              <Card className="border">
                <CustomDataTable
                  records={employeeTableData.records}
                  fetchData={employeeTableData.fetchData}
                  exportColumns={employeeTableData.exportColumns}
                  tableColumns={employeeTableData.tableColumns}
                  globalFilterFields={employeeTableData.globalFilterFields}
                  dataKey={employeeTableData.dataKey}
                />
              </Card>
            ) : (
              <div className="d-flex flex-column justify-content-center text-center mt-5">
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                <span>Just a moment...</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewEmployee
        show={showNewEmployeeModal}
        toggle={toggleNewEmployeeModal}
        getEmployees={getEmployees}
        isUpdate={isUpdate}
        selectedEmployee={selectedEmployee}
      />
    </div>
  );
}

export default AllEmployees;
