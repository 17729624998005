import axios from "axios";
import { BASE_URL } from "./api";

const baseURL = BASE_URL;

const protectedAPI = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer `,
  },
});

// Request interceptor
protectedAPI.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.setItem(
        "logout_msg",
        "Session expired. Please login again. ERR: NO_ACC_TKN_FND"
      );
      window.location.href = "/";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
protectedAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem("refreshToken");
      if (refreshToken) {
        try {
          const response = await axios.post(`${baseURL}auth/refreshToken`, {
            refreshToken: refreshToken,
          });
          if (response.status === 200) {
            localStorage.setItem("accessToken", response.data.accessToken);
            axios.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${response.data.accessToken}`;
            return protectedAPI(originalRequest);
          }
        } catch (err) {
          console.log("Error: ", err);
          localStorage.setItem(
            "logout_msg",
            "Session expired. Please login again. ERR: REFRESH_FAIL"
          );
          localStorage.removeItem("privilegedViews");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("user");
          window.location.href = "/";
        }
      } else {
        localStorage.setItem(
          "logout_msg",
          "Session expired. Please login again. ERR: NO_RFT_TKN_FND"
        );
        localStorage.removeItem("privilegedViews");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("user");
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);

export default protectedAPI;
