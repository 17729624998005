import React from "react";
import MenuBarContent from "./MenuBarContent";

function MenuBar() {
  return (
    <>
      <div className="sidebar border-end">
        <MenuBarContent />
      </div>
    </>
  );
}

export default MenuBar;
