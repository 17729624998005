import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button, Form } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import protectedAPI from "../../config/protectedAPI";
import { useToastProvider } from "../../providers/useToastProvider";
import { InputText } from "primereact/inputtext";

function NewUserAccount(props) {
  const { show, toggle, getAccounts, isUpdate, selectedUserAccount } = props;
  const showToast = useToastProvider();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [profiles, setProfiles] = useState([]);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const profileRef = useRef(null);
  const employeeRef = useRef(null);
  const saveBtnRef = useRef(null);

  const saveUserAccount = async () => {
    try {
      saveBtnRef.current.disabled = true;
      saveBtnRef.current.innerText = "Please wait...";

      if (!selectedProfile || (!isUpdate && !selectedEmployee)) {
        showToast({
          type: "warn",
          detail: "Please select a profile and employee.",
        });
        return;
      }

      const _url = isUpdate
        ? "settings/userAccount/updateGroupProfile/" + selectedUserAccount.id
        : "settings/userAccount/create";
      let data = null;
      if (!isUpdate) {
        data = {
          username: username,
          password: password,
          groupId: selectedProfile.id,
          empNo: selectedEmployee.empNo,
        };
      } else {
        data = {
          groupId: selectedProfile.id,
        };
      }
      const response = await protectedAPI.post(_url, data);
      if (response.status === 201 || response.status === 200) {
        getAccounts();
        showToast({
          type: "success",
          detail: response.data.message,
        });
        clearFields();
        toggle();
        getEmployees();
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      saveBtnRef.current.disabled = false;
      saveBtnRef.current.innerText = "Save";
    }
  };

  const getProfiles = async () => {
    try {
      const response = await protectedAPI.get("settings/groupProfile/all");
      if (response.status === 200) {
        setProfiles(response.data);
        if (isUpdate && selectedUserAccount) {
          setSelectedProfile(
            response.data.find(
              (profile) => profile.id === selectedUserAccount.groupId
            )
          );
        }
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const getEmployees = async () => {
    try {
      const response = await protectedAPI.get("employee/withoutUserAccounts");
      if (response.status === 200) {
        setEmployees(response.data);
        if (isUpdate && selectedUserAccount) {
          setSelectedEmployee(
            response.data.find(
              (employee) => employee.empNo === selectedUserAccount.empNo
            )
          );
        }
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const clearFields = () => {
    setUsername("");
    setPassword("");
    setSelectedProfile(null);
    setSelectedEmployee(null);
  };

  useEffect(() => {
    async function loadData() {
      await getProfiles();
      await getEmployees();
      if (isUpdate && selectedUserAccount) {
        setUsername(selectedUserAccount.username);
      } else {
        clearFields();
      }
    }
    loadData();
  }, [isUpdate, selectedUserAccount]);

  return (
    <>
      <Dialog
        header={isUpdate ? "Update User Account Details" : "New User Account"}
        visible={show}
        style={{ width: "30vw" }}
        breakpoints={{ "960px": "30vw", "641px": "100vw" }}
        onHide={() => {
          toggle();
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-column mb-3">
              <Form.Label htmlFor="groupProfile">
                Select Group Profile
              </Form.Label>
              <Dropdown
                value={selectedProfile}
                ref={profileRef}
                onChange={(e) => setSelectedProfile(e.value)}
                options={profiles}
                filter
                optionLabel="name"
                placeholder="Select a Profile"
                className="w-full md:w-14rem"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex flex-column mb-3">
              <Form.Label htmlFor="employee">Select Employee</Form.Label>
              <Dropdown
                value={selectedEmployee}
                ref={employeeRef}
                onChange={(e) => setSelectedEmployee(e.value)}
                options={employees}
                filter
                optionLabel={(option) =>
                  option.fullname == null ? option.name : option.fullname
                }
                placeholder="Select an Employee"
                className="w-full md:w-14rem"
                disabled={isUpdate}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="username">Username</Form.Label>
              <InputText
                id="username"
                ref={usernameRef}
                value={username}
                disabled={isUpdate}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
        {!isUpdate && (
          <div className="row">
            <div className="col-md-12">
              <Form.Group className="d-flex flex-column mb-3">
                <Form.Label htmlFor="password">Password</Form.Label>
                <InputText
                  id="password"
                  type="password"
                  ref={passwordRef}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
            </div>
          </div>
        )}
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end">
            <Button
              variant="info"
              className="p-2 px-4"
              ref={saveBtnRef}
              onClick={saveUserAccount}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default NewUserAccount;
