import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuCategoryItem from "./MenuCategoryItem";
import { useMenuProvider } from "../../providers/useMenuProvider";
import { UPLOADS_URL } from "../../config/api";

function MenuBarContent() {
  const { menuItems, getMenuItems, employee, groupProfile } = useMenuProvider();
  const [activeItem, setActiveItem] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    getMenuItems();
  }, []);

  return (
    <>
      <div className="d-flex flex-column">
        <div
          className="d-flex mt-3 mx-3 p-2 border-bottom menubar-profile"
          onClick={() => navigate("/user/account")}
        >
          <div className="me-3">
            <img
              src={
                employee?.image && employee?.image !== "null"
                  ? UPLOADS_URL + "employees/" + employee.image
                  : require("../../images/users/no-user.png")
              }
              alt="user-img"
              className="rounded-circle"
              width={40}
              height={40}
            />
          </div>
          <div className="d-flex flex-column justify-content-start">
            <div className="text-start fw-bold">
              <small>{employee?.nickname}</small>
            </div>
            <div className="text-start">
              <small>{groupProfile?.name}</small>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column mt-3 px-3 pb-4">
          {menuItems.map((item, index) =>
            item.items?.length > 1 ? (
              <MenuCategoryItem
                key={index}
                keyx={index}
                item={item}
                isOpen={activeItem === index}
                onToggle={() =>
                  setActiveItem(activeItem === index ? null : index)
                }
              />
            ) : (
              <Link
                key={index}
                to={item.items[0].url}
                className="btn menu-category-btn d-flex justify-content-between"
                style={{ fontWeight: "400" }}
              >
                <span>
                  <i
                    className={item.icon + " me-2"}
                    style={{ fontSize: "16px" }}
                  ></i>{" "}
                  {item.items[0].label}
                </span>
              </Link>
            )
          )}
        </div>
      </div>
    </>
  );
}

export default MenuBarContent;
