import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/Menubar/MenuBar";
import { useToastProvider } from "../../providers/useToastProvider";
import { Button } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import CustomDataTable from "../../components/Tables/CustomDataTable";
import { ProgressSpinner } from "primereact/progressspinner";
import NewModule from "../../components/MasterFiles/NewModule";
import { Card } from "primereact/card";
import { useConfirmDialogProvider } from "../../providers/useConfirmDialogProvider";
import { ProgressBar } from "primereact/progressbar";
import { Dropdown } from "primereact/dropdown";

function AllModules() {
  // const navigate = useNavigate();
  const showToast = useToastProvider();
  const showConfirmDialog = useConfirmDialogProvider();

  const [isLoading, setIsLoading] = useState(false);
  const [showNewModuleModal, setShowNewModuleModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [modules, setModules] = useState([]);

  const [selectedProject, setSelectedProject] = useState({
    id: 0,
    title: "All Projects",
  });
  const [selectedModule, setSelectedModule] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const toggleNewModule = () => {
    setShowNewModuleModal(!showNewModuleModal);
  };

  const getProjects = async () => {
    try {
      const response = await protectedAPI.get("project/all");
      if (response.status === 200) {
        response.data.unshift({
          id: 0,
          title: "All Projects",
        });
        setProjects(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const getModules = async (_selectedProject) => {
    try {
      setIsLoading(true);
      const response = await protectedAPI.get(
        "module/all?projectId=" + _selectedProject?.id || 0
      );
      if (response.status === 200) {
        setModules(response.data);
      }
    } catch (error) {
      setModules([]);
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteModule = async (moduleId) => {
    try {
      const response = await protectedAPI.delete("module/delete/" + moduleId);
      if (response.status === 200) {
        getModules();
        showToast({
          type: "success",
          detail: response.data.message,
        });
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const deleteModuleConfirm = (moduleId) => {
    showConfirmDialog({
      message: "Are you sure you want to delete this module?",
      onAccept: () => deleteModule(moduleId),
    });
  };

  const openEditModal = (module) => {
    setSelectedModule(module);
    setIsUpdate(true);
    toggleNewModule();
  };

  const rowNoTemplate = (data, options) => {
    return options.rowIndex + 1;
  };

  const codeTemplate = (module) => {
    return (
      <small
        className="badge text-bg-dark border fw-light"
        style={{ width: "fit-content" }}
      >
        {module.code}
      </small>
    );
  };

  const titleTemplate = (module) => {
    return (
      <>
        <div className="d-flex flex-column">
          <small className="fw-bold">{module.title}</small>
          <small className="text-muted" style={{ width: "fit-content" }}>
            {module.projectTitle}
          </small>
        </div>
      </>
    );
  };

  const progressTemplate = (module) => {
    return (
      <>
        <div className="d-flex flex-column mt-2">
          <div className="d-flex justify-content-between mb-2">
            <small>35%</small>
          </div>
          <ProgressBar
            value={35}
            style={{ height: "5px" }}
            showValue={false}
          ></ProgressBar>
        </div>
      </>
    );
  };

  const modulesTestCasesTemplate = (module) => {
    return (
      <>
        <div className="d-flex">
          <span className="badge bg-warning-subtle border border-warning fw-normal">
            0 / 28
          </span>
        </div>
      </>
    );
  };

  const actionTemplate = (module) => {
    return (
      <>
        <div className="btn-group" role="group">
          <button className="btn btn-sm bg-transparent border">
            <i className="pi pi-eye"></i>
          </button>
          <button
            type="button"
            className="btn btn-sm bg-transparent border"
            onClick={() => openEditModal(module)}
          >
            <i className="pi pi-pencil"></i>
          </button>
          <button
            type="button"
            className="btn btn-sm bg-transparent border text-danger"
            onClick={() => deleteModuleConfirm(module.id)}
          >
            <i className="pi pi-trash"></i>
          </button>
        </div>
      </>
    );
  };

  const moduleTableData = {
    fetchData: getModules,
    records: modules,
    exportColumns: [
      { header: "ID", field: "id" },
      { header: "Project ID", field: "projectId" },
      { header: "Project", field: "projectTitle" },
      { header: "Code", field: "code" },
      { header: "Title", field: "title" },
      { header: "Description", field: "description" },
    ],
    tableColumns: [
      { header: "#", body: rowNoTemplate, style: { width: "50px" } },
      { header: "_Code", field: "code", style: { display: "none" } },
      { header: "Code", body: codeTemplate },
      { header: "_Title", field: "title", style: { display: "none" } },
      { header: "Title", body: titleTemplate },
      { header: "Test Cases", body: modulesTestCasesTemplate },
      { header: "Progress", body: progressTemplate },
      { header: "", body: actionTemplate, style: { width: "100px" } },
    ],
    globalFilterFields: ["code", "title"],
    dataKey: "id",
  };

  useEffect(() => {
    getProjects();
  }, []);

  useEffect(() => {
    getModules(selectedProject);
  }, [selectedProject]);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="Modules" />
        <div className="d-flex flex-column p-4">
          <div className="d-flex justify-content-between">
            <div>
              <Button
                variant="info"
                onClick={() => {
                  setSelectedModule(null);
                  setIsUpdate(false);
                  toggleNewModule();
                }}
              >
                <i className="pi pi-plus me-1"></i> Create New Module
              </Button>
            </div>
            <div className="d-flex">
              <div className="d-flex align-items-center me-3">
                <span>
                  <i className="pi pi-filter"></i>
                </span>
              </div>
              <Dropdown
                value={selectedProject}
                onChange={(e) => setSelectedProject(e.value)}
                options={projects}
                filter
                optionLabel="title"
                style={{ width: "200px" }}
              />
            </div>
          </div>
          <div className="mt-3">
            {!isLoading ? (
              <Card className="border">
                <CustomDataTable
                  records={moduleTableData.records}
                  fetchData={moduleTableData.fetchData}
                  exportColumns={moduleTableData.exportColumns}
                  tableColumns={moduleTableData.tableColumns}
                  globalFilterFields={moduleTableData.globalFilterFields}
                  dataKey={moduleTableData.dataKey}
                />
              </Card>
            ) : (
              <div className="d-flex flex-column justify-content-center text-center mt-5">
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                <span>Just a moment...</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewModule
        show={showNewModuleModal}
        toggle={toggleNewModule}
        getModules={getModules}
        isUpdate={isUpdate}
        selectedModule={selectedModule}
      />
    </div>
  );
}

export default AllModules;
