import { useEffect, useState } from "react";
import Header from "../components/Header/Header";
import MenuBar from "../components/Menubar/MenuBar";

function App() {
  const formatTime = (time) => {
    const options = { hour: "2-digit", minute: "2-digit", hour12: true };
    return new Date(time)
      .toLocaleTimeString([], { ...options, hour12: true })
      .replace(" am", " AM")
      .replace(" pm", " PM");
  };

  const formatDate = (date) => {
    const options = {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
    };
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  const [time, setTime] = useState(formatTime(new Date()));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(formatTime(new Date()));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="Testing Reporting & Control Evaluation" />
        <div className="d-flex flex-column p-4">
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <h3 className="fw-bold">Dashboard</h3>
              <small>
                Here's a brief overview of your business performance.
              </small>
            </div>
            <div className="d-flex flex-column text-end">
              <h3 className="fw-medium">{time}</h3>
              <small>{formatDate(new Date())}</small>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-3">
              <div className="card bg-success-subtle w-100">
                <div className="card-body">
                  <h6 className="card-title">Total Projects</h6>
                  <h2 className="card-text fw-bold">0</h2>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card bg-primary-subtle w-100">
                <div className="card-body">
                  <h6 className="card-title">Total Modules</h6>
                  <h2 className="card-text fw-bold">0</h2>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card bg-warning-subtle w-100">
                <div className="card-body">
                  <h6 className="card-title">Total Test Cases</h6>
                  <h2 className="card-text fw-bold">0</h2>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="card bg-danger-subtle w-100">
                <div className="card-body">
                  <h6 className="card-title">Total Progress</h6>
                  <h2 className="card-text fw-bold">0%</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
