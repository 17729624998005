import React, { createContext, useContext, useRef } from "react";
import { Toast } from "primereact/toast";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toast = useRef(null);

  const showNotification = ({ type, detail }) => {
    const summary =
      type === "success"
        ? "Success"
        : type === "info"
        ? "Info"
        : type === "warn"
        ? "Warning"
        : "Error";
    toast.current.show({ severity: type, summary, detail });
  };

  return (
    <ToastContext.Provider value={showNotification}>
      <Toast ref={toast} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToastProvider = () => useContext(ToastContext);
