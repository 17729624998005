import { Card } from "primereact/card";
import Header from "../components/Header/Header";
import MenuBar from "../components/Menubar/MenuBar";
import { useMenuProvider } from "../providers/useMenuProvider";
import { UPLOADS_URL } from "../config/api";
import { useEffect, useRef, useState } from "react";
import { FormGroup } from "react-bootstrap";
import { InputText } from "primereact/inputtext";
import protectedAPI from "../config/protectedAPI";
import { useToastProvider } from "../providers/useToastProvider";

function MyAccount() {
  const { employee, groupProfile } = useMenuProvider();
  const showToast = useToastProvider();

  const [user, setUser] = useState(null);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passwordSaveBtnRef = useRef(null);

  const changePassword = async () => {
    try {
      if (!oldPassword || !newPassword || !confirmPassword) {
        showToast({
          type: "warn",
          detail: "All fields are required.",
        });
        return;
      }

      if (newPassword !== confirmPassword) {
        showToast({
          type: "warn",
          detail: "New password and confirm password do not match.",
        });
        return;
      }

      passwordSaveBtnRef.current.disabled = true;
      passwordSaveBtnRef.current.innerText = "Please wait...";

      const response = await protectedAPI.post(
        `settings/userAccount/updatePassword/${user.id}`,
        {
          oldPassword: oldPassword,
          newPassword: newPassword,
        }
      );

      if (response.status === 200) {
        showToast({
          type: "success",
          detail: response.data.message,
        });
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      passwordSaveBtnRef.current.disabled = false;
      passwordSaveBtnRef.current.innerText = "Save";
    }
  };

  useEffect(() => {
    const _user = JSON.parse(localStorage.getItem("user"));
    setUser(_user);
  }, []);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="My Account" />
        <div className="d-flex flex-column p-4">
          <Card className="border p-4 py-2 pt-3">
            <div className="row">
              <div className="col-7 border-end">
                <div className="d-flex">
                  <div className="d-flex flex-column">
                    <h5 className="fw-medium text-info mb-5">
                      General Information
                    </h5>
                    <div className="mb-4">
                      <img
                        src={
                          employee?.image && employee?.image !== "null"
                            ? UPLOADS_URL + "employees/" + employee.image
                            : require("../images/users/no-user.png")
                        }
                        alt="no-user-img"
                        className="rounded-circle"
                        width={200}
                        height={200}
                      />
                    </div>
                    <h2 className="fw-bold">
                      {employee?.fullname || employee?.nickname}
                    </h2>
                    <div className="d-flex">
                      <span
                        className="badge text-bg-dark border fw-light me-2"
                        style={{ fontSize: "12px" }}
                      >
                        {groupProfile?.name}
                      </span>
                      <span
                        className="badge text-bg-dark border fw-light me-2"
                        style={{ fontSize: "12px" }}
                      >
                        <i className="bi bi-person-lock me-1"></i>{" "}
                        {user?.username}
                      </span>
                    </div>
                    <div className="mt-4 d-flex flex-column">
                      <div className="d-flex mt-3">
                        <div className="me-5">
                          <small className="fw-light text-secondary">
                            Employee No.
                          </small>
                          <p>{employee?.empNo || "--"}</p>
                        </div>
                        <div className="me-5">
                          <small className="fw-light text-secondary">
                            Account No.
                          </small>
                          <p>{employee?.accNo || "--"}</p>
                        </div>
                        <div className="me-5">
                          <small className="fw-light text-secondary">
                            National Identity No.
                          </small>
                          <p>{employee?.nic || "--"}</p>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="me-5">
                          <small className="fw-light text-secondary">
                            Address
                          </small>
                          <p>{employee?.address || "--"}</p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <h5 className="fw-medium text-info">
                        Contact Information
                      </h5>
                      <div className="d-flex mt-3">
                        <div className="me-5">
                          <small className="fw-light text-secondary">
                            Email
                          </small>
                          <p>{employee?.email || "--"}</p>
                        </div>
                        <div>
                          <small className="fw-light text-secondary">
                            Mobile
                          </small>
                          <p>{employee?.mobile || "--"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-5 px-5">
                <div className="d-flex flex-column">
                  <h5 className="fw-medium mb-5 text-info">
                    Change Your Password
                  </h5>
                  <FormGroup className="d-flex flex-column w-100 mb-3">
                    <label htmlFor="currentPassword" className="form-label">
                      Current Password
                    </label>
                    <InputText
                      id="currentPassword"
                      type="password"
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="d-flex flex-column w-100 mb-3">
                    <label htmlFor="newPassword" className="form-label">
                      New Password
                    </label>
                    <InputText
                      id="newPassword"
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </FormGroup>
                  <FormGroup className="d-flex flex-column w-100 mb-3">
                    <label htmlFor="confirmPassword" className="form-label">
                      Confirm New Password
                    </label>
                    <InputText
                      id="confirmPassword"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </FormGroup>
                  <button
                    className="btn btn-info mt-4"
                    onClick={changePassword}
                    ref={passwordSaveBtnRef}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default MyAccount;
