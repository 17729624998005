import { useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/Menubar/MenuBar";
import { useToastProvider } from "../../providers/useToastProvider";
import { Form } from "react-bootstrap";
import { Dropdown } from "primereact/dropdown";
import protectedAPI from "../../config/protectedAPI";
import CustomDataTable from "../../components/Tables/CustomDataTable";
import { InputSwitch } from "primereact/inputswitch";
import { useMenuProvider } from "../../providers/useMenuProvider";
import { Card } from "primereact/card";
import { Message } from "primereact/message";
import { ProgressSpinner } from "primereact/progressspinner";

function GroupPrivilege() {
  // const navigate = useNavigate();
  const showToast = useToastProvider();
  const { getMenuItems } = useMenuProvider();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedGroupProfile, setSelectedGroupProfile] = useState(null);
  const [menuCategories, setMenuCategories] = useState([]);
  const [categoryItems, setCategoryItems] = useState({});
  const [groupProfiles, setGroupProfiles] = useState([]);

  const groupProfileRef = useRef(null);

  const getGroupProfiles = async () => {
    try {
      const response = await protectedAPI.get("settings/groupProfile/all");
      if (response.status === 200) {
        setGroupProfiles(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const getMenuCategories = async () => {
    try {
      const response = await protectedAPI.get("settings/menuCategory/all");
      if (response.status === 200) {
        setMenuCategories(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const getCategoryItems = async (categoryId) => {
    try {
      const response = await protectedAPI.get(
        "settings/groupPrivilege/all/" +
          selectedGroupProfile.id +
          "/" +
          categoryId
      );
      if (response.status === 200) {
        setCategoryItems((prevItems) => ({
          ...prevItems,
          [categoryId]: response.data,
        }));
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const getProfilePrivileges = async (profile) => {
    if (profile) {
      setIsLoading(true);
      await getMenuCategories();
      setIsLoading(false);
    }
  };

  const addPrivilege = async (item, category) => {
    try {
      const response = await protectedAPI.post(
        "settings/groupPrivilege/create",
        {
          groupId: selectedGroupProfile.id,
          menuListId: item.id,
        }
      );
      if (response.status === 201) {
        getCategoryItems(category.id);
        getMenuItems();
        showToast({
          type: "success",
          detail: "Access granted successfully.",
        });
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const removePrivilege = async (item, category) => {
    try {
      const response = await protectedAPI.delete(
        "settings/groupPrivilege/delete/" + item.privilegeId
      );
      if (response.status === 200) {
        getCategoryItems(category.id);
        getMenuItems();
        showToast({
          type: "success",
          detail: "Access removed successfully.",
        });
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const rowNoTemplate = (data, options) => {
    return options.rowIndex + 1;
  };

  const accessTemplate = (item, category) => {
    return (
      <InputSwitch
        checked={item.hasPrivilege}
        onChange={() =>
          item.hasPrivilege
            ? removePrivilege(item, category)
            : addPrivilege(item, category)
        }
      />
    );
  };

  const urlTemplate = (item) => {
    return (
      <>
        <span
          className="badge text-bg-dark border fw-light"
          style={{ fontSize: "12px" }}
        >
          {item.url}
        </span>
      </>
    );
  };

  useEffect(() => {
    getGroupProfiles();
  }, []);

  useEffect(() => {
    if (menuCategories.length > 0) {
      menuCategories.forEach((category) => {
        getCategoryItems(category.id);
      });
    }
  }, [menuCategories]);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="Group Privileges" />
        <div className="d-flex flex-column p-4">
          <div className="d-flex">
            <Card className="border w-100">
              <div className="d-flex flex-column">
                <Form.Label htmlFor="groupProfile">
                  Select Group Profile
                </Form.Label>
                <Dropdown
                  value={selectedGroupProfile}
                  ref={groupProfileRef}
                  onChange={(e) => {
                    setSelectedGroupProfile(e.value);
                    getProfilePrivileges(e.value);
                  }}
                  options={groupProfiles}
                  filter
                  optionLabel="name"
                  placeholder="Select"
                  className="w-full md:w-14rem"
                  showClear
                />
              </div>
            </Card>
          </div>
          <div className="mt-3">
            {selectedGroupProfile ? (
              <>
                {!isLoading ? (
                  <>
                    {menuCategories.map((category) => (
                      <Card key={category.id} className="border mt-3 py-0">
                        <h6 className="fw-bold text-info">{category.name}</h6>
                        <CustomDataTable
                          records={categoryItems[category.id] || []}
                          fetchData={() => getCategoryItems(category.id)}
                          exportColumns={null}
                          tableColumns={[
                            {
                              header: "#",
                              body: rowNoTemplate,
                              style: { width: "50px" },
                            },
                            { header: "Menu Item", field: "name" },
                            {
                              header: "URL",
                              body: urlTemplate,
                            },
                            {
                              header: "Access",
                              body: (item) => accessTemplate(item, category),
                              style: { width: "120px" },
                            },
                          ]}
                          globalFilterFields={["name"]}
                          dataKey="id"
                        />
                      </Card>
                    ))}
                  </>
                ) : (
                  <div className="d-flex flex-column justify-content-center text-center mt-5">
                    <ProgressSpinner
                      style={{ width: "50px", height: "50px" }}
                    />
                    <span>Just a moment...</span>
                  </div>
                )}
              </>
            ) : (
              <div
                className="d-flex flex-column justify-content-center text-center"
                style={{ fontSize: "0.2rem" }}
              >
                <Message
                  severity="warn"
                  text="No group profile selected. Please select a group profile to
                  view group privileges."
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GroupPrivilege;
