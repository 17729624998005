import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import MenuBarContent from "./MenuBarContent";

function MobileMenuBar(props) {
  const { show, toggle } = props;
  return (
    <>
      <Offcanvas show={show} onHide={toggle} className="mobile-sidebar w-75">
        <Offcanvas.Body className="px-0">
          <MenuBarContent />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default MobileMenuBar;
