import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button, Form } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import { useToastProvider } from "../../providers/useToastProvider";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import SEVERITY_OPTIONS from "../../config/severityOptions";

function NewTestCase(props) {
  const { show, toggle, getTestCases, isUpdate, selectedTestCase } = props;
  const showToast = useToastProvider();

  const [testcases, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [title, setTitle] = useState("");
  const [ref, setRef] = useState("");
  const [description, setDescription] = useState("");
  const [severity, setSeverity] = useState(null);

  const selectedModuleRef = useRef(null);
  const titleRef = useRef(null);
  const refRef = useRef(null);
  const descriptionRef = useRef(null);
  const saveBtnRef = useRef(null);

  const severityOptions = SEVERITY_OPTIONS.slice(1);

  const saveTestCase = async () => {
    try {
      if (!title || !ref || !selectedModule || !severity) {
        showToast({
          type: "warn",
          detail: "Title, Ref & Severity are required.",
        });
        return;
      }

      saveBtnRef.current.disabled = true;
      saveBtnRef.current.innerText = "Please wait...";

      const url = isUpdate
        ? "testcase/update/" + selectedTestCase.id
        : "testcase/create";
      const response = await protectedAPI.post(url, {
        moduleId: selectedModule.id,
        title: title,
        ref: ref,
        description: description,
        severity: severity,
      });
      if (response.status === 201 || response.status === 200) {
        getTestCases(selectedModule);
        showToast({
          type: "success",
          detail: response.data.message,
        });
        clearFields();
        toggle();
      }
    } catch (error) {
      console.error(error);
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      saveBtnRef.current.disabled = false;
      saveBtnRef.current.innerText = "Save";
    }
  };

  const severityTemplate = (option) => {
    return (
      <div className="d-flex align-items-center">
        <span
          className="rounded-circle p-1 me-3"
          style={{ backgroundColor: option?.color }}
        ></span>
        {option?.label}
      </div>
    );
  };

  const clearFields = () => {
    setSelectedModule(null);
    setTitle("");
    setRef("");
    setDescription("");
    setSeverity(null);
  };

  const getModules = async () => {
    try {
      const response = await protectedAPI.get("module/all");
      if (response.status === 200) {
        setModules(response.data);
        if (isUpdate && selectedTestCase) {
          setSelectedModule(
            response.data.find(
              (module) => module.id === selectedTestCase.moduleId
            )
          );
        }
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  useEffect(() => {
    if (isUpdate && selectedTestCase) {
      setTitle(selectedTestCase.title);
      setRef(selectedTestCase.ref);
      setDescription(selectedTestCase.description);
      setSeverity(selectedTestCase.severity);
    } else {
      clearFields();
    }

    getModules();
  }, [isUpdate, selectedTestCase]);

  return (
    <>
      <Dialog
        header={isUpdate ? "Update Test Case Details" : "New Test Case"}
        visible={show}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => {
          toggle();
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="moduleId">Module</Form.Label>
              <Dropdown
                ref={selectedModuleRef}
                value={selectedModule}
                onChange={(e) => setSelectedModule(e.value)}
                options={testcases}
                filter
                optionLabel="title"
                placeholder="Select a Module"
                className="w-full md:w-14rem"
              />
            </Form.Group>
          </div>
          <div className="col-md-3">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="ref">Ref</Form.Label>
              <InputText
                id="ref"
                ref={refRef}
                value={ref}
                onChange={(e) => setRef(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-9">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <InputText
                id="title"
                ref={titleRef}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="description">Description</Form.Label>
              <InputTextarea
                id="description"
                ref={descriptionRef}
                value={description}
                rows={5}
                cols={30}
                autoResize
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="severity">Severity</Form.Label>
              <Dropdown
                value={severity}
                onChange={(e) => setSeverity(e.value)}
                itemTemplate={severityTemplate}
                valueTemplate={severityTemplate}
                options={severityOptions}
                optionLabel="label"
                placeholder="Select a Severity"
                className="w-full md:w-14rem"
                style={{ height: "35px" }}
                filter
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end">
            <Button
              variant="info"
              className="p-2 px-4"
              ref={saveBtnRef}
              onClick={saveTestCase}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default NewTestCase;
