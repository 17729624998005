import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/Menubar/MenuBar";
import { useToastProvider } from "../../providers/useToastProvider";
import { Button } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import CustomDataTable from "../../components/Tables/CustomDataTable";
import { ProgressSpinner } from "primereact/progressspinner";
import NewTestCase from "../../components/MasterFiles/NewTestCase";
import { Card } from "primereact/card";
import { useConfirmDialogProvider } from "../../providers/useConfirmDialogProvider";
import { Dropdown } from "primereact/dropdown";
import SEVERITY_OPTIONS from "../../config/severityOptions";

function AllTestCases() {
  // const navigate = useNavigate();
  const showToast = useToastProvider();
  const showConfirmDialog = useConfirmDialogProvider();

  const [isLoading, setIsLoading] = useState(false);
  const [showNewTestCaseModal, setShowNewTestCaseModal] = useState(false);
  const [modules, setModules] = useState([]);
  const [testcases, setTestCases] = useState([]);

  const severityOptions = SEVERITY_OPTIONS;

  const [selectedModule, setSelectedModule] = useState({
    id: 0,
    title: "All Modules",
  });
  const [selectedTestCase, setSelectedTestCase] = useState(null);
  const [selectedSeverity, setSelectedSeverity] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);

  const toggleNewTestCase = () => {
    setShowNewTestCaseModal(!showNewTestCaseModal);
  };

  const getModules = async () => {
    try {
      const response = await protectedAPI.get("module/all");
      if (response.status === 200) {
        response.data.unshift({
          id: 0,
          title: "All Modules",
        });
        setModules(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const getTestCases = async (_selectedModule, _selectedSeverity) => {
    try {
      setIsLoading(true);
      const response = await protectedAPI.get(
        "testcase/all?moduleId=" +
          (_selectedModule?.id || 0) +
          "&severity=" +
          (_selectedSeverity || 0)
      );
      if (response.status === 200) {
        setTestCases(response.data);
      }
    } catch (error) {
      setTestCases([]);
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteTestCase = async (testcaseId) => {
    try {
      const response = await protectedAPI.delete(
        "testcase/delete/" + testcaseId
      );
      if (response.status === 200) {
        getTestCases();
        showToast({
          type: "success",
          detail: response.data.message,
        });
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const deleteTestCaseConfirm = (testcaseId) => {
    showConfirmDialog({
      message: "Are you sure you want to delete this testcase?",
      onAccept: () => deleteTestCase(testcaseId),
    });
  };

  const openEditModal = (testcase) => {
    setSelectedTestCase(testcase);
    setIsUpdate(true);
    toggleNewTestCase();
  };

  const rowNoTemplate = (data, options) => {
    return options.rowIndex + 1;
  };

  const codeTemplate = (testcase) => {
    return (
      <small
        className="badge text-bg-dark border fw-light"
        style={{ width: "fit-content" }}
      >
        {testcase.ref}
      </small>
    );
  };

  const titleTemplate = (testcase) => {
    return (
      <>
        <div className="d-flex flex-column">
          <small className="fw-bold">{testcase.title}</small>
          <small className="text-muted" style={{ width: "fit-content" }}>
            {testcase.moduleTitle}
          </small>
        </div>
      </>
    );
  };

  const severityTemplate = (testcase) => {
    return (
      <span
        style={{
          backgroundColor:
            testcase.severity == 1
              ? "green"
              : testcase.severity == 2
              ? "orange"
              : "red",
          fontSize: "10px",
        }}
        className="badge fw-bold p-1"
      >
        {testcase.severity == 1
          ? "Low"
          : testcase.severity == 2
          ? "Medium"
          : "High"}
      </span>
    );
  };

  const severityDropdownTemplate = (option) => {
    return (
      <div className="d-flex align-items-center">
        <span
          className="rounded-circle p-1 me-2"
          style={{ backgroundColor: option?.color }}
        ></span>
        {option?.label}
      </div>
    );
  };

  const actionTemplate = (testcase) => {
    return (
      <>
        <div className="btn-group" role="group">
          <button className="btn btn-sm bg-transparent border">
            <i className="pi pi-eye"></i>
          </button>
          <button
            type="button"
            className="btn btn-sm bg-transparent border"
            onClick={() => openEditModal(testcase)}
          >
            <i className="pi pi-pencil"></i>
          </button>
          <button
            type="button"
            className="btn btn-sm bg-transparent border text-danger"
            onClick={() => deleteTestCaseConfirm(testcase.id)}
          >
            <i className="pi pi-trash"></i>
          </button>
        </div>
      </>
    );
  };

  const testcaseTableData = {
    fetchData: getTestCases,
    records: testcases,
    exportColumns: [
      { header: "ID", field: "id" },
      { header: "Module ID", field: "moduleId" },
      { header: "Module", field: "moduleTitle" },
      { header: "Ref", field: "ref" },
      { header: "Title", field: "title" },
      { header: "Description", field: "description" },
      { header: "Severity", field: "severity" },
    ],
    tableColumns: [
      { header: "#", body: rowNoTemplate, style: { width: "50px" } },
      { header: "_Ref", field: "ref", style: { display: "none" } },
      { header: "Ref", body: codeTemplate },
      { header: "_Title", field: "title", style: { display: "none" } },
      { header: "Title", body: titleTemplate },
      { header: "Severity", body: severityTemplate },
      { header: "", body: actionTemplate, style: { width: "100px" } },
    ],
    globalFilterFields: ["ref", "title"],
    dataKey: "id",
  };

  useEffect(() => {
    getModules();
  }, []);

  useEffect(() => {
    getTestCases(selectedModule, selectedSeverity);
  }, [selectedModule, selectedSeverity]);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="TestCases" />
        <div className="d-flex flex-column p-4">
          <div className="d-flex justify-content-between">
            <div>
              <Button
                variant="info"
                onClick={() => {
                  setSelectedTestCase(null);
                  setIsUpdate(false);
                  toggleNewTestCase();
                }}
              >
                <i className="pi pi-plus me-1"></i> Create New TestCase
              </Button>
            </div>
            <div className="d-flex">
              <div className="d-flex align-items-center me-3">
                <span>
                  <i className="pi pi-filter"></i>
                </span>
              </div>
              <Dropdown
                value={selectedSeverity}
                onChange={(e) => setSelectedSeverity(e.value)}
                options={severityOptions}
                itemTemplate={severityDropdownTemplate}
                valueTemplate={severityDropdownTemplate}
                filter
                optionLabel="label"
                style={{ width: "180px" }}
                className="me-1"
              />
              <Dropdown
                value={selectedModule}
                onChange={(e) => setSelectedModule(e.value)}
                options={modules}
                filter
                optionLabel="title"
                style={{ width: "200px" }}
              />
            </div>
          </div>
          <div className="mt-3">
            {!isLoading ? (
              <Card className="border">
                <CustomDataTable
                  records={testcaseTableData.records}
                  fetchData={testcaseTableData.fetchData}
                  exportColumns={testcaseTableData.exportColumns}
                  tableColumns={testcaseTableData.tableColumns}
                  globalFilterFields={testcaseTableData.globalFilterFields}
                  dataKey={testcaseTableData.dataKey}
                />
              </Card>
            ) : (
              <div className="d-flex flex-column justify-content-center text-center mt-5">
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                <span>Just a moment...</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewTestCase
        show={showNewTestCaseModal}
        toggle={toggleNewTestCase}
        getTestCases={getTestCases}
        isUpdate={isUpdate}
        selectedTestCase={selectedTestCase}
      />
    </div>
  );
}

export default AllTestCases;
