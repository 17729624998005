import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/Menubar/MenuBar";
import { useToastProvider } from "../../providers/useToastProvider";
import { Button } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import CustomDataTable from "../../components/Tables/CustomDataTable";
import { ProgressSpinner } from "primereact/progressspinner";
import NewProject from "../../components/MasterFiles/NewProject";
import { Card } from "primereact/card";
import { useConfirmDialogProvider } from "../../providers/useConfirmDialogProvider";
import { SelectButton } from "primereact/selectbutton";
import { ProgressBar } from "primereact/progressbar";
import AssignProjectManager from "../../components/MasterFiles/AssignProjectManager";

function AllProjects() {
  // const navigate = useNavigate();
  const showToast = useToastProvider();
  const showConfirmDialog = useConfirmDialogProvider();

  const [isLoading, setIsLoading] = useState(false);
  const [showNewProjectModal, setShowNewProjectModal] = useState(false);
  const [showAssignProjectManagerModal, setShowAssignProjectManagerModal] =
    useState(false);
  const [projects, setProjects] = useState([]);

  const [selectedProject, setSelectedProject] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [view, setView] = useState("list");

  const viewTemplate = (option) => {
    return <i className={option.icon}></i>;
  };

  const views = [
    { name: "List", value: "list", icon: "pi pi-list" },
    { name: "Table", value: "table", icon: "pi pi-table" },
  ];

  const toggleNewProject = () => {
    setShowNewProjectModal(!showNewProjectModal);
  };

  const toggleAssignProjectManagerModal = () => {
    setShowAssignProjectManagerModal(!showAssignProjectManagerModal);
  };

  const getProjects = async () => {
    try {
      setIsLoading(true);
      const response = await protectedAPI.get("project/all");
      if (response.status === 200) {
        setProjects(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteProject = async (projectId) => {
    try {
      const response = await protectedAPI.delete("project/delete/" + projectId);
      if (response.status === 200) {
        getProjects();
        showToast({
          type: "success",
          detail: response.data.message,
        });
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const deleteProjectConfirm = (projectId) => {
    showConfirmDialog({
      message: "Are you sure you want to delete this project?",
      onAccept: () => deleteProject(projectId),
    });
  };

  const openEditModal = (project) => {
    setSelectedProject(project);
    setIsUpdate(true);
    toggleNewProject();
  };

  const openAssignProjectModal = (project) => {
    setSelectedProject(project);
    toggleAssignProjectManagerModal();
  };

  const rowNoTemplate = (data, options) => {
    return options.rowIndex + 1;
  };

  const titleTemplate = (project) => {
    return (
      <>
        <div className="d-flex flex-column">
          <small className="fw-bold">{project.title}</small>
          <small
            className="badge text-bg-dark border fw-light"
            style={{ width: "fit-content" }}
          >
            {project.code}
          </small>
        </div>
      </>
    );
  };

  const progressTemplate = (project) => {
    return (
      <>
        <div className="d-flex flex-column mt-2">
          <div className="d-flex justify-content-between mb-2">
            <small>35%</small>
          </div>
          <ProgressBar
            value={35}
            style={{ height: "5px" }}
            showValue={false}
          ></ProgressBar>
        </div>
      </>
    );
  };

  const descriptionTemplate = (project) => {
    return (
      <>
        <p className="mt-3 text-muted" style={{ fontSize: "12px" }}>
          {project.description?.length > 100
            ? project.description.substring(0, 100) + "..."
            : project.description}
        </p>
      </>
    );
  };

  const projectManagerTemplate = (project) => {
    return (
      <>
        <div className="d-flex mb-2">
          <small>
            {project.projectManager ? (
              <>
                <i className="pi pi-briefcase me-2"></i>
                {project.projectManager.fullname ||
                  project.projectManager.nickname}
              </>
            ) : (
              <a
                href="#"
                className="link-secondary"
                onClick={() => {
                  openAssignProjectModal(project);
                }}
                style={{ fontSize: "12px" }}
              >
                <i className="pi pi-plus me-2"></i>
                Assign Project Manager
              </a>
            )}
          </small>
        </div>
      </>
    );
  };

  const modulesTestCasesTemplate = (project) => {
    return (
      <>
        <div className="d-flex">
          <span className="badge bg-warning-subtle fw-normal me-2">
            7 Modules
          </span>
          <span className="badge bg-info-subtle fw-normal">28 Test Cases</span>
        </div>
      </>
    );
  };

  const actionTemplate = (project) => {
    return (
      <>
        <div className="btn-group" role="group">
          <button className="btn btn-sm bg-transparent border">
            <i className="pi pi-eye"></i>
          </button>
          <button className="btn btn-sm bg-transparent border">
            <i className="pi pi-briefcase"></i>
          </button>
          <button className="btn btn-sm bg-transparent border">
            <i className="pi pi-users"></i>
          </button>
          <button
            type="button"
            className="btn btn-sm bg-transparent border"
            onClick={() => openEditModal(project)}
          >
            <i className="pi pi-pencil"></i>
          </button>
          <button
            type="button"
            className="btn btn-sm bg-transparent border text-danger"
            onClick={() => deleteProjectConfirm(project.id)}
          >
            <i className="pi pi-trash"></i>
          </button>
        </div>
      </>
    );
  };

  const projectTableData = {
    fetchData: getProjects,
    records: projects,
    exportColumns: [
      { header: "ID", field: "id" },
      { header: "Code", field: "code" },
      { header: "Title", field: "title" },
      { header: "Description", field: "description" },
    ],
    tableColumns: [
      { header: "#", body: rowNoTemplate, style: { width: "50px" } },
      {
        header: "_Code",
        field: "code",
        sortable: true,
        style: { display: "none" },
      },
      {
        header: "_Title",
        field: "title",
        sortable: true,
        style: { display: "none" },
      },
      { header: "Title", body: titleTemplate },
      { header: "Description", body: descriptionTemplate },
      { header: "Project Manager", body: projectManagerTemplate },
      { header: "Modules", body: modulesTestCasesTemplate },
      { header: "Progress", body: progressTemplate },
      { header: "", body: actionTemplate, style: { width: "100px" } },
    ],
    globalFilterFields: ["code", "title"],
    dataKey: "id",
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="Projects" />
        <div className="d-flex flex-column p-4">
          <div className="d-flex justify-content-between">
            <div>
              <Button
                variant="info"
                onClick={() => {
                  setSelectedProject(null);
                  setIsUpdate(false);
                  toggleNewProject();
                }}
              >
                <i className="pi pi-plus me-1"></i> Create New Project
              </Button>
            </div>
            <div>
              <SelectButton
                value={view}
                onChange={(e) => setView(e.value)}
                itemTemplate={viewTemplate}
                optionLabel="name"
                options={views}
              />
            </div>
          </div>
          <div className="mt-3">
            {!isLoading ? (
              view === "table" ? (
                <Card className="border">
                  <CustomDataTable
                    records={projectTableData.records}
                    fetchData={projectTableData.fetchData}
                    exportColumns={projectTableData.exportColumns}
                    tableColumns={projectTableData.tableColumns}
                    globalFilterFields={projectTableData.globalFilterFields}
                    dataKey={projectTableData.dataKey}
                  />
                </Card>
              ) : (
                <>
                  <div className="container-fluid px-0">
                    <div className="row mb-3">
                      <strong>
                        We found {projects.length} project
                        {projects.length > 1 && "s"} .
                      </strong>
                    </div>
                    <div className="row">
                      {projects.map((project) => (
                        <div className="col-12 col-sm-4 mb-4" key={project.id}>
                          <Card
                            key={project.id}
                            className="project-card dark-gradient-bg border h-100"
                            style={{ minHeight: "200px" }}
                          >
                            <h5 className="fw-bold">{project.title}</h5>
                            <small className="badge text-bg-dark border fw-light">
                              {project.code}
                            </small>
                            {descriptionTemplate(project)}
                            {projectManagerTemplate(project)}
                            {modulesTestCasesTemplate(project)}
                            {progressTemplate(project)}
                            <div className="d-flex justify-content-between mt-4">
                              <div>
                                <button className="btn btn-sm bg-transparent border me-1">
                                  <i className="pi pi-eye"></i>
                                </button>
                                <button className="btn btn-sm bg-transparent border me-1">
                                  <i className="pi pi-briefcase"></i>
                                </button>
                                <button className="btn btn-sm bg-transparent border me-1">
                                  <i className="pi pi-users"></i>
                                </button>
                              </div>
                              <div>
                                <button className="btn btn-sm bg-transparent border me-1">
                                  <i className="pi pi-pencil"></i>
                                </button>
                                <button className="btn btn-sm bg-transparent border text-danger">
                                  <i className="pi pi-trash"></i>
                                </button>
                              </div>
                            </div>
                          </Card>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )
            ) : (
              <div className="d-flex flex-column justify-content-center text-center mt-5">
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                <span>Just a moment...</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewProject
        show={showNewProjectModal}
        toggle={toggleNewProject}
        getProjects={getProjects}
        isUpdate={isUpdate}
        selectedProject={selectedProject}
      />
      <AssignProjectManager
        show={showAssignProjectManagerModal}
        toggle={toggleAssignProjectManagerModal}
        reloadProjects={getProjects}
        _selectedProject={selectedProject}
      />
    </div>
  );
}

export default AllProjects;
