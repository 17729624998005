import { useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { FilterMatchMode } from "primereact/api";

function CustomDataTable(props) {
  const {
    records,
    fetchData,
    exportColumns,
    tableColumns,
    globalFilterFields,
    dataKey,
  } = props;

  const tableRef = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const cols = exportColumns;

  const exportCols = cols?.map((col) => ({
    title: col.header,
    dataKey: col.field,
  }));

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const refreshButtonRef = useRef(null);
  const exportButtonRef = useRef(null);
  const printButtonRef = useRef(null);

  const renderHeader = () => {
    return (
      <div className="d-flex flex-column flex-sm-row justify-content-sm-between">
        <div className="btn-group" role="group">
          <button
            ref={refreshButtonRef}
            className="btn btn-sm btn-secondary-outline px-3 border"
            onClick={fetchData}
          >
            <i className="pi pi-sync me-1"></i> Refresh
          </button>
          {exportColumns && (
            <>
              <button
                ref={exportButtonRef}
                className="btn btn-sm btn-secondary-outline px-3 border"
                onClick={() => exportCSV(false)}
              >
                <i className="pi pi-file-export me-1"></i> Export
              </button>
              <button
                ref={printButtonRef}
                className="btn btn-sm btn-secondary-outline px-3 border"
                onClick={exportPdf}
              >
                <i className="pi pi-print me-1"></i> Print
              </button>
            </>
          )}
        </div>
        <div className="mt-2 mt-sm-0">
          <IconField iconPosition="left">
            <InputIcon className="pi pi-search" />
            <InputText
              className="w-100"
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search by keyword"
              style={{ paddingLeft: "2.5rem" }}
            />
          </IconField>
        </div>
      </div>
    );
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const exportCSV = async (selectionOnly) => {
    await tableRef.current.exportCSV({ selectionOnly });
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);

        doc.autoTable(exportCols, records);
        doc.save("data.pdf");
      });
    });
  };

  const header = renderHeader();

  return (
    <>
      {records ? (
        <DataTable
          ref={tableRef}
          size="small"
          value={records}
          dataKey={dataKey}
          stripedRows
          selectionMode="single"
          sortMode="multiple"
          removableSort
          paginator
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "50rem" }}
          paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          filters={filters}
          filterDisplay="menu"
          globalFilterFields={globalFilterFields}
          header={header}
          emptyMessage="No data found."
        >
          {tableColumns?.map((col, index) => (
            <Column
              key={index}
              header={col.header}
              field={!col.body ? col.field : undefined}
              body={col.body ? col.body : undefined}
              style={col.style ? col.style : undefined}
              sortable={col.sortable ? col.sortable : false}
            />
          ))}
        </DataTable>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomDataTable;
