import React, { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button, Form } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import { useToastProvider } from "../../providers/useToastProvider";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

function NewProject(props) {
  const { show, toggle, getProjects, isUpdate, selectedProject } = props;
  const showToast = useToastProvider();

  const [title, setTitle] = useState("");
  const [code, setCode] = useState("");
  const [description, setDescription] = useState("");

  const titleRef = useRef(null);
  const codeRef = useRef(null);
  const descriptionRef = useRef(null);
  const saveBtnRef = useRef(null);

  const saveProject = async () => {
    try {
      if (!title || !code) {
        showToast({
          type: "warn",
          detail: "Title and code are required.",
        });
        return;
      }

      saveBtnRef.current.disabled = true;
      saveBtnRef.current.innerText = "Please wait...";

      const url = isUpdate
        ? "project/update/" + selectedProject.id
        : "project/create";
      const response = await protectedAPI.post(url, {
        title: title,
        code: code,
        description: description,
      });
      if (response.status === 201 || response.status === 200) {
        getProjects();
        showToast({
          type: "success",
          detail: response.data.message,
        });
        clearFields();
        toggle();
      }
    } catch (error) {
      console.error(error);
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      saveBtnRef.current.disabled = false;
      saveBtnRef.current.innerText = "Save";
    }
  };

  const clearFields = () => {
    setTitle("");
    setCode("");
    setDescription("");
  };

  useEffect(() => {
    if (isUpdate && selectedProject) {
      setTitle(selectedProject.title);
      setCode(selectedProject.code);
      setDescription(selectedProject.description);
    } else {
      clearFields();
    }
  }, [isUpdate, selectedProject]);

  return (
    <>
      <Dialog
        header={isUpdate ? "Update Project Details" : "New Project"}
        visible={show}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        onHide={() => {
          toggle();
        }}
      >
        <div className="row">
          <div className="col-md-3">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="code">Code</Form.Label>
              <InputText
                id="code"
                ref={codeRef}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-9">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="title">Title</Form.Label>
              <InputText
                id="title"
                ref={titleRef}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
          </div>
          <div className="col-md-12">
            <Form.Group className="d-flex flex-column mb-3">
              <Form.Label htmlFor="description">Description</Form.Label>
              <InputTextarea
                id="description"
                ref={descriptionRef}
                value={description}
                rows={5}
                cols={30}
                autoResize
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-12 d-flex justify-content-end">
            <Button
              variant="info"
              className="p-2 px-4"
              ref={saveBtnRef}
              onClick={saveProject}
            >
              Save
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default NewProject;
