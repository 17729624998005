import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import MenuBar from "../../components/Menubar/MenuBar";
import { useToastProvider } from "../../providers/useToastProvider";
import { Button } from "react-bootstrap";
import protectedAPI from "../../config/protectedAPI";
import CustomDataTable from "../../components/Tables/CustomDataTable";
import { ProgressSpinner } from "primereact/progressspinner";
import NewMenuCategory from "../../components/Settings/NewMenuCategory";
import { Card } from "primereact/card";
import { useMenuProvider } from "../../providers/useMenuProvider";
import { useConfirmDialogProvider } from "../../providers/useConfirmDialogProvider";

function MenuCategory() {
  // const navigate = useNavigate();
  const showToast = useToastProvider();
  const showConfirmDialog = useConfirmDialogProvider();
  const { getMenuItems } = useMenuProvider();

  const [isLoading, setIsLoading] = useState(false);
  const [showNewMenuCategoryModal, setShowNewMenuCategoryModal] =
    useState(false);
  const [menuCategories, setMenuCategories] = useState([]);

  const [selectedMenuCategory, setSelectedMenuCategory] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);

  const toggleNewMenuCategory = () => {
    setShowNewMenuCategoryModal(!showNewMenuCategoryModal);
  };

  const getMenuCategories = async () => {
    try {
      setIsLoading(true);
      const response = await protectedAPI.get("settings/menuCategory/all");
      if (response.status === 200) {
        setMenuCategories(response.data);
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteMenuCategory = async (menuCategoryId) => {
    try {
      const response = await protectedAPI.delete(
        "settings/menuCategory/delete/" + menuCategoryId
      );
      if (response.status === 200) {
        getMenuItems();
        getMenuCategories();
        showToast({
          type: "success",
          detail: response.data.message,
        });
      }
    } catch (error) {
      showToast({
        type: error.response?.status < 500 ? "warn" : "error",
        detail:
          error.response?.data?.message ||
          "An error occurred. Please try again.",
      });
    }
  };

  const deleteMenuCategoryConfirm = (menuCategoryId) => {
    showConfirmDialog({
      message:
        "Are you sure you want to delete this menu category? If you delete this category, all the menus under this category will be deleted.",
      onAccept: () => deleteMenuCategory(menuCategoryId),
    });
  };

  const openEditModal = (menuCategory) => {
    setSelectedMenuCategory(menuCategory);
    setIsUpdate(true);
    toggleNewMenuCategory();
  };

  const rowNoTemplate = (data, options) => {
    return options.rowIndex + 1;
  };

  const actionTemplate = (menuCategory) => {
    return (
      <>
        <div className="btn-group" role="group">
          <button
            type="button"
            className="btn btn-sm bg-transparent border"
            onClick={() => openEditModal(menuCategory)}
          >
            <i className="pi pi-pencil"></i>
          </button>
          <button
            type="button"
            className="btn btn-sm bg-transparent border text-danger"
            onClick={() => deleteMenuCategoryConfirm(menuCategory.id)}
          >
            <i className="pi pi-trash"></i>
          </button>
        </div>
      </>
    );
  };

  const iconTemplate = (menuCategory) => {
    return menuCategory.icon ? (
      <div className="d-flex align-items-center">
        <i className={menuCategory.icon}></i>
        <span className="ms-2">{menuCategory.icon}</span>
      </div>
    ) : null;
  };

  const menuCategoryTableData = {
    fetchData: getMenuCategories,
    records: menuCategories,
    exportColumns: [
      { header: "ID", field: "id" },
      { header: "Name", field: "name" },
      { header: "Icon", field: "icon" },
      { header: "order", field: "order" },
    ],
    tableColumns: [
      { header: "#", body: rowNoTemplate, style: { width: "50px" } },
      { header: "Category", field: "name", sortable: true },
      { header: "Icon", body: iconTemplate },
      { header: "Order", field: "order", sortable: true },
      { header: "", body: actionTemplate, style: { width: "100px" } },
    ],
    globalFilterFields: ["name", "icon", "order"],
    dataKey: "id",
  };

  useEffect(() => {
    getMenuCategories();
  }, []);

  return (
    <div className="d-flex main-content">
      <MenuBar />
      <div className="d-flex flex-column page">
        <Header pageTitle="Menu Categories" />
        <div className="d-flex flex-column p-4">
          <div className="d-flex">
            <Button
              variant="info"
              onClick={() => {
                setSelectedMenuCategory(null);
                setIsUpdate(false);
                toggleNewMenuCategory();
              }}
            >
              <i className="pi pi-plus me-1"></i> New Menu Category
            </Button>
          </div>
          <div className="mt-3">
            {!isLoading ? (
              <Card className="border">
                <CustomDataTable
                  records={menuCategoryTableData.records}
                  fetchData={menuCategoryTableData.fetchData}
                  exportColumns={menuCategoryTableData.exportColumns}
                  tableColumns={menuCategoryTableData.tableColumns}
                  globalFilterFields={menuCategoryTableData.globalFilterFields}
                  dataKey={menuCategoryTableData.dataKey}
                />
              </Card>
            ) : (
              <div className="d-flex flex-column justify-content-center text-center mt-5">
                <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                <span>Just a moment...</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewMenuCategory
        show={showNewMenuCategoryModal}
        toggle={toggleNewMenuCategory}
        getCategories={getMenuCategories}
        isUpdate={isUpdate}
        selectedMenuCategory={selectedMenuCategory}
      />
    </div>
  );
}

export default MenuCategory;
